import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter as Router, BrowserRouter, Switch, Route, Link } from "react-router-dom";

import Signin from "./signin";
import SignUp from "./signup";
// import Dashboard from "./dashboard";
// import Payout from "./payout";
import AdManager from "./admanager";
import AnswerEditor from "./answerEditor";
import AnswerTranslator from "./answerTranslater";
import Forgot from "./forgot";
import LanguageManager from "./languageManager";
import KeywordAdManager from "./keywordadmanager";
import CatagoryManager from "./catagory";
import KeywordManager from "./keyword";
import ProductManager from "./product";
import DisclaimerEditor from "./disclaimereditor";

const BASE_URL = "https://dev.skinexam.com";

class SkinExam extends React.Component {
  constructor(props) {
    super(props);
    this.setUserData = this.setUserData.bind(this);
    this.setLoggedIn = this.setLoggedIn.bind(this);
    this.state = {
      userdata: {},
      loggedin: true, //false,
    };
  }
  componentDidMount = () => {      
        const pageAccessedByReload = (
            (window.performance.navigation && window.performance.navigation.type === 1) ||
            window.performance
                .getEntriesByType('navigation')
                .map((nav) => nav.type)
                .includes('reload')
        );
        if (pageAccessedByReload) {
        console.info("This page is reloaded");
        // history.go(0);
        } else {
        console.info("This page is not reloaded");
        }
  }


  setUserData = (userData) => {
    this.setState({
      userdata: userData,
    });
    console.log(userData);
  };

  setLoggedIn = (innum) => {
      console.log(innum);
    if (innum === 0) {
      this.setState({
        loggedin: false,
      });
    } else {
      this.setState({
        loggedin: true,
      });
    }
  };

  render() {
    let showLinks;
    let hideLinks;
    if (this.state.loggedin) {
      showLinks = "hiddenLinks";
      hideLinks = "publicLinks";
    } else {
      showLinks = "publicLinks";
      hideLinks = "hiddenLinks";
    }
    return (
      <Router>
        <div>
          <nav className="navBar">
            <ul>
              <li className={showLinks}>
                <Link to="/">Sign In</Link>
              </li>
              <li className={showLinks}>
                <Link to="/signup">Sign Up</Link>
              </li>
              <li className={hideLinks}>
                <Link to="/signout">Sign Out</Link>
              </li>
              {/* <li className={hideLinks}>
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className={hideLinks}>
                <Link to="/payout">Request Payment</Link>
              </li> */}
              <li className={hideLinks}>
                <Link to="/admanager">Base Ad Manager</Link>
              </li>
              <li className={hideLinks}>
                <Link to="/langmanager">Language Manager</Link>
              </li>
              {/* <li className={hideLinks}>
                <Link to="/answermanager">Answer Manager</Link>
              </li> */}
              <li className={hideLinks}>
                <Link to="/answertranslator">Answer Translater</Link>
              </li>
              {/* <li className={hideLinks}>
                <Link to="/keywordadmanager">Keyword Ad Manager</Link>
              </li> */}
              <li className={hideLinks}>
                <Link to="/keyword">Keyword</Link>
              </li>
              <li className={hideLinks}>
                <Link to="/category">Category</Link>
              </li>
              <li className={hideLinks}>
                <Link to="/product">Product</Link>
              </li>
              <li className={hideLinks}>
                <Link to="/disclaimer">Disclaimer</Link>
              </li>
            </ul>
          </nav>

          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
            <Route exact path="/signup" BASE_URL={BASE_URL}>
              <SignUp userData={this.state.userdata} />
            </Route>
            <Route exact path="/signout" BASE_URL={BASE_URL}>
              <Signin
                setUserData={this.setUserData}
                userData={this.state.userdata}
                setLoggedIn={this.setLoggedIn}
              />
            </Route>
            {/* <Route exact path="/dashboard" BASE_URL={BASE_URL}>
              <Dashboard
                userdata={this.state.userdata}
                setLoggedIn={this.setLoggedIn}
              />
               <Payout userdata={this.state.userdata} />
            </Route>
            <Route exact path="/payout" BASE_URL={BASE_URL}>
              <Payout userdata={this.state.userdata} />
            </Route> */}
            <Route exact path="/admanager" BASE_URL={BASE_URL}>
              <AdManager
                user={this.state.userdata} />
            </Route>
            <Route
              exact
              path="/answermanager"
              BASE_URL={BASE_URL}
            >
              <AnswerEditor 
                user={this.state.userdata} />
            </Route>
            <Route exact path="/" BASE_URL={BASE_URL}>
              <Signin
                setUserData={this.setUserData}
                userData={this.state.userdata}
                setLoggedIn={this.setLoggedIn}
              />
            </Route>
            <Route exact path="/answertranslator" BASE_URL={BASE_URL}>
                <AnswerTranslator user={this.state.userdata}/>
            </Route>
            <Route exact path="/forgot" BASE_URL={BASE_URL}>
                <Forgot />
            </Route>
            <Route exact path="/langmanager" BASE_URL={BASE_URL}>
                <LanguageManager user={this.state.userdata} />
            </Route>
            <Route exact path="/keywordadmanager" BASE_URL={BASE_URL}>
                <KeywordAdManager user={this.state.userdata} />
            </Route>
            <Route exact path="/category" BASE_URL={BASE_URL}>
                <CatagoryManager
                user={this.state.userdata}  />
            </Route>
            <Route exact path="/keyword" BASE_URL={BASE_URL}>
                <KeywordManager
                user={this.state.userdata}  />
            </Route>
            <Route exact path="/product" BASE_URL={BASE_URL}>
                <ProductManager 
                user={this.state.userdata} />
            </Route>
            <Route exact path="/disclaimer" BASE_URL={BASE_URL}>
                <DisclaimerEditor />
            </Route>

          </Switch>
        </div>
      </Router>
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <SkinExam></SkinExam>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
