//ID Num	Name	Thinks	Possible	Non Pres	Pres	TO DO	NOT TODO

import MyEditor from "./richEditOr2";
// import MyEditor2 from "./richEditOr2";

import React from "react";

class AnswerTranslator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        DiseaseId: 1,
        LanguageId:1,
        langLanguageId:1,
        diseases:[],
        languages:[],
        
        originalDiseaseId: 0,
        originalName: "",
        originalDescription: "",
        originalPossible: "",
        originalNonPrescription: "",
        originalPrescription: "",
        originalThingsToDo: "",
        originalThingsNotToDo: "",
        
        langDiseaseId: 0,
        langName: "",
        langDescription: "",
        langPossible: "",
        langNonPrescription: "",
        langPrescription: "",
        langThingsToDo: "",
        langThingsNotToDo: "",
    };
  }
  

    componentDidMount  = () => {
        fetch(
            "https://dev.skinexam.com/answers/readwithlang.php?DiseaseId=1&LanguageId=1" //, requestOptions
          )
          .then((res) => res.json())
          .then((result) => {
                this.setState({
                      originalDiseaseId: result.DiseaseId,
                      originalName:result.Name,
                      originalDescription: result.Description,
                      originalPossible: result.Possible,
                      originalNonPrescription: result.NonPres,
                      originalPrescription: result.Pres,
                      originalThingsToDo: result.ToDo,
                      originalThingsNotToDo: result.NotToDo
                })
            }).then(() => {
                fetch(
                    "https://dev.skinexam.com/answers/readwithlang.php?DiseaseId=1&LanguageId=1" //, requestOptions
                    )
                .then((res) => res.json())
                .then((result) => {
                    this.setState({
                        langDiseaseId: result.Id,
                        langName:result.Name,
                        langDescription: result.Description,
                        langPossible: result.Possible,
                        langNonPrescription: result.NonPres,
                        langPrescription: result.Pres,
                        langThingsToDo: result.ToDo,
                        langThingsNotToDo: result.NotToDo
                    })
                }).then(() => {
                    
                    document.getElementsByName("showstuff").forEach((element)=>{
                        element.click();
                    })
                });
            }).then(() => {
                fetch(
                    "https://dev.skinexam.com/diseases/read.php"
                ).then((res) => res.json()).then((result) => {
                        this.setState({
                            diseases: result,
                        });
                }).then(() => {
                    document.getElementsByName("showstuff").forEach((element)=>{
                        element.click();
                    })
                }).then(() => {
                    fetch("https://dev.skinexam.com/languages/read.php").then(res => res.json()).then(result => {
                        this.setState({
                            languages: result,
                        });
                    });
                });
            })
        
  };

  updateLanguageLeft = (e) => {
    let languageId = e.target.value;
    let diseaseId = this.state.DiseaseId;
    // console.log(languageId);
    // let disId = this.state.DiseaseId;
    // console.log(diseaseId);
    // console.log(languageId)
    // console.log(true);
    fetch(
        "https://dev.skinexam.com/answers/readwithlang.php?DiseaseId=" +
          diseaseId + "&LanguageId=" + languageId //, requestOptions
      )
      .then((res) => res.json())
      .then((result) => {
            this.setState({
                  originalDiseaseId: result.DiseaseId,
                  originalName:result.Name,
                  originalDescription: result.Description,
                  originalPossible: result.Possible,
                  originalNonPrescription: result.NonPres,
                  originalPrescription: result.Pres,
                  originalThingsToDo: result.ToDo,
                  originalThingsNotToDo: result.NotToDo
            })
        }).then(() => {
          
          document.getElementsByName("showstuff").forEach((element)=>{
              element.click();
          })
      });
    this.setState({
        LanguageId: languageId
    }); 
}
updateLanguageRight = (e) => {
    let languageId = e.target.value;
    let diseaseId = this.state.DiseaseId;
    // console.log(languageId);
    // // let disId = this.state.DiseaseId;
    // console.log(diseaseId);
    // console.log(languageId)
    // console.log(true);
    fetch(
        "https://dev.skinexam.com/answers/readwithlang.php?DiseaseId=" +
          diseaseId + "&LanguageId=" + languageId //, requestOptions
      )
      .then((res) => res.json())
      .then((result) => {
            this.setState({
                langDiseaseId: result.Id,
                langName:result.Name,
                langDescription: result.Description,
                langPossible: result.Possible,
                langNonPrescription: result.NonPres,
                langPrescription: result.Pres,
                langThingsToDo: result.ToDo,
                langThingsNotToDo: result.NotToDo
            })
        }).then(() => {
          
          document.getElementsByName("showstuff").forEach((element)=>{
              element.click();
          })
      });
    this.setState({
        langLanguageId: languageId
    }); 
}
  

  updateDiseaseFields = (diseaseId,language) => {
    fetch(
      "https://dev.skinexam.com/answers/readwithlang.php?DiseaseId=" +
        diseaseId + "&LanguageId=" + language //, requestOptions
    )
    .then((res) => res.json())
    .then((result) => {
        this.setState({
            langDiseaseId: result.Id,
            langName:result.Name,
            langDescription: result.Description,
            langPossible: result.Possible,
            langNonPrescription: result.NonPres,
            langPrescription: result.Pres,
            langThingsToDo: result.ToDo,
            langThingsNotToDo: result.NotToDo
    
        })
    }).then(() => {
        fetch(
            "https://dev.skinexam.com/answers/readwithlang.php?DiseaseId=" +
              diseaseId + "&LanguageId=1"
          )
          .then((res) => res.json())
          .then((result) => {
              this.setState({
                  originalDiseaseId: result.DiseaseId,
                  originalName:result.Name,
                  originalDescription: result.Description,
                  originalPossible: result.Possible,
                  originalNonPrescription: result.NonPres,
                  originalPrescription: result.Pres,
                  originalThingsToDo: result.ToDo,
                  originalThingsNotToDo: result.NotToDo
              })
        }).then(() => {
            
            document.getElementsByName("showstuff").forEach((element)=>{
                element.click();
            })
        });
    });
  }

  upload2 = (input, zone) => {

  }

  upload = (input, zone) => {
    switch (zone) {
      case 1:
        // this.setState({
        //   DiseaseId: input,
        // });
        break;
      case 2:
        // this.setState({
        //   langName: input,
        // });
        break;
      case 3:
        this.setState({
            langDescription: input,
        });
        break;
      case 4:
        this.setState({
            langPossible: input,
        });
        break;
      case 5:
        this.setState({
            langNonPrescription: input,
        });
        break;
      case 6:
        this.setState({
            langPrescription: input,
        });
        break;
      case 7:
        this.setState({
            langThingsToDo: input,
        });
        break;
      case 8:
        this.setState({
            langThingsNotToDo: input,
        });
        break;
      default:
        break;
    }
  };

  submit = () => {
    let requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
      },
      body: JSON.stringify({
        id: this.state.langDiseaseId,
        name: this.state.langName,
        description: this.state.langDescription,
        possible: this.state.langPossible,
        nonpres: this.state.langNonPrescription,
        pres: this.state.langPrescription,
        todo: this.state.langThingsToDo,
        nottodo: this.state.langThingsNotToDo
      }),
    };
    // console.log(requestOptions);
    fetch(
      "https://dev.skinexam.com/answers/update.php",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        alert("success");
      });
  };

    updateDisease = (e) => {
        let disId = e.target.value;
        let languageId = this.state.langLanguageId;
        this.setState({
            // originalDiseaseId: disId,
            // langDiseaseId: disId,
            DiseaseId:disId,
        },() => {
            this.updateDiseaseFields(disId,languageId);
        });
    }

  render() {
    // console.log(this.state.answer.DiseaseId);
    // console.log(this.state.englishdisease.DiseaseId);
    
    const theWholeField = (
      <div>      
        <table>
            <thead>
                <tr><td className="inputhead"></td><td className="input"><h1>AnswerEditor</h1></td><td className="input"></td></tr>
                <tr><td className="inputhead"></td><td className="input">   
                    <DiseasesDropdown diseaseId={this.state.originalDiseaseId} updateDisease={this.updateDisease} diseases={this.state.diseases}></DiseasesDropdown>
                </td><td className="input">
                  <LanguageDropdown LanguageId={this.state.langLanguageId} updateLanguage={this.updateLanguageRight} languages={this.state.languages}></LanguageDropdown>
              </td></tr>
                </thead>
            <tbody>
            <tr>
              <td className="inputhead">
                <label>Num</label>
              </td>
              <td className="input">
                <p>{((this.state.originalDiseaseId === null)?("no disease id"):(this.state.originalDiseaseId))}</p>
              </td>
              <td className="input">
                <p>{((this.state.langDiseaseId === null)?("<p></p>"):(this.state.langDiseaseId))}</p>
              </td>
            </tr>
            <tr>
              <td className="inputhead">
                <label>Name</label>
              </td>
              <td className="input">
                <MyEditor
                  initital={((this.state.originalName === null || this.state.originalName === undefined)?("No Disease Name"):(this.state.originalName))}
                  zone={'a'}
                  upload={this.upload2}
                />
              </td>
              <td className="input">
                <MyEditor
                  initital={((this.state.langName === null)?("<p></p>"):(this.state.langName))}
                  zone={2}
                  upload={this.upload}
                />
              </td>
            </tr>
            <tr>
              <td className="inputhead">
                <label>Thinks</label>
              </td>
              <td className="input">
                 <MyEditor
                  initital={((this.state.originalDescription === null || this.state.originalDescription === undefined)?("No Description"):(this.state.originalDescription))}
                  zone={'b'}
                  upload={this.upload2}
                />
              </td>
              <td className="input">
                <MyEditor
                  initital={((this.state.langDescription === null)?("<p></p>"):(this.state.langDescription))}
                  zone={3}
                  upload={this.upload}
                />
              </td>
            </tr>
            <tr>
              <td className="inputhead">
                <label>Possible</label>
              </td>
              <td className="input">
                <MyEditor
                  initital={((this.state.originalPossible === null || this.state.originalPossible === undefined)?("No Possible"):(this.state.originalPossible))}
                  zone={'c'}
                  upload={this.upload2}
                />
              </td>
              <td className="input">
                <MyEditor
                  initital={((this.state.langPossible === null)?("<p></p>"):(this.state.langPossible))}
                  zone={4}
                  upload={this.upload}
                />
              </td>
            </tr>
            <tr>
              <td className="inputhead">
                <label>ToDo</label>
              </td>
              <td className="input">
                <MyEditor
                  initital={((this.state.originalThingsToDo === null || this.state.originalThingsToDo === undefined)?("No Todo"):(this.state.originalThingsToDo))}
                  zone={'d'}
                  upload={this.upload2}
                />
              </td>
              <td className="input">
                <MyEditor
                  initital={((this.state.langThingsToDo === null)?("<p></p>"):(this.state.langThingsToDo))}
                  zone={7}
                  upload={this.upload}
                />
              </td>
            </tr>
            <tr>
              <td className="inputhead">
                <label>Not ToDo</label>
              </td>
              <td className="input">
                <MyEditor
                  initital={((this.state.originalThingsNotToDo === null || this.state.originalThingsNotToDo === undefined)?("No Not Todo"):(this.state.originalThingsNotToDo))}
                  zone={'e'}
                  upload={this.upload2}
                />
              </td>
              <td className="input">
                <MyEditor
                  initital={((this.state.langThingsNotToDo === null)?("<p></p>"):(this.state.langThingsNotToDo))}
                  zone={8}
                  upload={this.upload}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                {/* <button onClick={this.submit}>Submit and upload</button> */}
              </td>
              <td>
                <button onClick={this.submit}>Submit and upload</button>
              </td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
        <div style={{ height: "50px", width: "100%" }}></div>
      </div>
    );
    return theWholeField;
  }
}



class DiseasesDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    // console.log(this.props.diseases);
    const theWholeField = (
        <select onChange={this.props.updateDisease} value={this.props.diseaseId}>
            {/* <option key={Math.random(50000)}></option> */}
            {this.props.diseases.map(disease => {
                return <option key={Math.random(50000)} id={disease.DiseaseId} value={disease.DiseaseId}>{disease.DiseaseName}</option>
            })}
        </select>
    );
    return theWholeField;
  }
}



class LanguageDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        // console.log(this.props.languages);
        const theWholeField = (
            <select onChange={this.props.updateLanguage} value={this.props.LanguageId}>
                <option key={Math.random(50000)}>Language</option>
                {this.props.languages.map(language => {
                    return <option key={Math.random(50000)} id={language.id} value={language.id}>{language.lang}</option>
                })}
            </select>
        );
        return theWholeField;
    }
}







export default AnswerTranslator;
