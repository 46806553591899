import React from "react";

class CatagoryManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories:[],
            updates:[],
            name:"",
            link:"",
            page:0,
            count:15
        }
    }

    componentDidMount = () => {
        const requestOptions = {
            method: "GET",
            headers: {
            "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
            // 'Accept': 'application/json',
            "Content-Type": "application/json",
            },
        };
        fetch("https://dev.skinexam.com/product_category/read.php?page=" + this.state.page + "&count=" + this.state.count,requestOptions).then(res => res.json()).then(result => {
            this.setState({
                categories:result
            })          
        });
    }


    editCat = () => {
        const updates = this.state.updates;
        const requestOptions = {
            method: 'PUT',
            headers: { 
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,'Content-Type': 'application/json' },
            body: JSON.stringify({
                updates:updates,
            })
        };
        fetch("https://dev.skinexam.com/product_category/update.php", requestOptions)
        .then(res => res.json())
        .then(result => {
            alert("Updated: " + JSON.stringify(result));
        });
    }

    updateCatLink = e => {
        let cat = e.target.name;
        let val = e.target.value;
        let alterupdates = [...this.state.updates,{'id':cat,'link':val}];
        this.setState({
            updates:alterupdates
        },() => {
            this.updatecat(cat,null,val);
        })
    }

    updateCatName = e => {
        let cat = e.target.name;
        let val = e.target.value;
        let alterupdates = [...this.state.updates,{'id':cat,'name':val}];
        this.setState({
            updates:alterupdates,
        },() => {
            this.updatecat(cat,val,null);
        })
    }


    updatecat = (id,nam,link) => {
        let oldobj = this.state.categories.find(obj => parseInt(obj.cat_id) === parseInt(id));
        let newobj = {};
        newobj.cat_id = oldobj.cat_id;
        newobj.cat_date = oldobj.cat_date;
        newobj.name = nam;
        newobj.cat_name = oldobj.cat_name;
        newobj.link = link;
        newobj.cat_link = oldobj.cat_link;        

        if(nam !== '' && nam !== undefined && nam !== null)
        {
            newobj.name = nam;
        }else{
            newobj.name = oldobj.name;
        }
        if(link !== '' && link !== undefined && link !== null)
        {
            newobj.link = link;
        }else{
            newobj.link = oldobj.link;
        }
        
        let updated = this.state.categories.map(obj => (parseInt(obj.cat_id) === parseInt(id) ? newobj : obj));
        
        console.log(updated);
        this.setState({
            categories:updated
        })
    }

    addCategory = () => {
        const name = this.state.name;
        const link = this.state.link;
        const requestOptions = {
            method: 'POST',
            headers: { 
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,'Content-Type': 'application/json' },
            body: JSON.stringify({
                name:name,
                link:link,
            })
        };
        fetch("https://dev.skinexam.com/product_category/create.php", requestOptions).then(res => res.json()).then(result => {
            console.log(result);
            alert("Added: " + JSON.stringify(result));
        });
    }

    updateCatNewName = e => {
        let val = e.target.value;
        this.setState({
            name:val
        },() => {
            const requestOptions = {
                method: "GET",
                headers: {
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
                // 'Accept': 'application/json',
                "Content-Type": "application/json",
                },
            };
            fetch("https://dev.skinexam.com/product_category/read.php?page=" + this.state.page + "&count=" + this.state.count + "&like=" + this.state.name,requestOptions).then(res => res.json()).then(result => {
                this.setState({
                    categories:result
                })
            });
        })
    }

    updateCatNewLink = e => {
        let val = e.target.value;
        this.setState({
            link:val
        })
    }

    
    addPage = () => {
        let page = this.state.page + 1;
        this.setState({
            page: page
        },() => {
            const requestOptions = {
                method: "GET",
                headers: {
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
                // 'Accept': 'application/json',
                "Content-Type": "application/json",
                },
            };
            fetch("https://dev.skinexam.com/product_category/read.php?page=" + this.state.page + "&count=" + this.state.count + "&like=" + this.state.name,requestOptions).then(res => res.json()).then(result => {
                this.setState({
                    categories:result
                })          
            });
        })
    }
    subPage = () => {
        let page = this.state.page - 1;
        if(this.state.page - 1 <= 0)
        {
            page = 0;
        }
        this.setState({
            page: page
        },() => {
            const requestOptions = {
                method: "GET",
                headers: {
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
                // 'Accept': 'application/json',
                "Content-Type": "application/json",
                },
            };
            fetch("https://dev.skinexam.com/product_category/read.php?page=" + this.state.page + "&count=" + this.state.count + "&like=" + this.state.name,requestOptions).then(res => res.json()).then(result => {
                this.setState({
                    categories:result
                })          
            });
        })
    }

    deleteCat = e => {
        let id = parseInt(e.target.name);
        const requestOptions = {
            method: 'DELETE',
            headers: { 
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,'Content-Type': 'application/json' },
            body: JSON.stringify({
                id:id,
            })
        };
        fetch("https://dev.skinexam.com/product_category/delete.php", requestOptions).then(res => res.json()).then(result => {
            console.log(result);
            alert("Deleted: " + JSON.stringify(result));
        });
    }

    testall = () => {
        let cats = this.state.categories;
        for(let i = 0; i < cats.length; ++i){
            window.open(cats[i].cat_link,'_blank');
        };
    }

    render() {
        const theWholeField = (
            <table className="producttable">
            <thead></thead>
            <tbody>
                <tr><td>
            <h1>CatagoryManager</h1>
            </td>
            {/* <td><button onClick={this.testall}>Test All</button></td> */}
            </tr><tr><td>
            <input placeholder="New Name" onKeyUp={this.updateCatNewName} />    
            </td><td>
            <input placeholder="New Link" onKeyUp={this.updateCatNewLink}/>
            </td><td>
            <button onClick={this.addCategory}>Add Category</button>
            </td></tr>
            {
                this.state.categories.map(cat => {
                    return ( 
                    <tr key={Math.random(50000)}>
                        <td key={Math.random(50000)}>
                        <input key={Math.random(50000)} name={cat.cat_id} title={cat.cat_name} placeholder={cat.cat_name} defaultValue={cat.name} onBlur={this.updateCatName} />    
                        </td><td key={Math.random(50000)}>
                        <input key={Math.random(50000)} name={cat.cat_id} title={cat.cat_link} placeholder={cat.cat_link} defaultValue={cat.link} onBlur={this.updateCatLink} />
                        </td><td key={Math.random(50000)}>
                            <button key={Math.random(50000)} onClick={this.deleteCat} name={cat.cat_id}>Delete</button>
                        </td><td key={Math.random(50000)}>
                        <a href={cat.cat_link}>Test</a>
                        </td>
                    </tr> );
                })
            }
            <tr><td>
                <button onClick={this.editCat} className="confirmbutton">Commit Updates</button>
            </td><td>
                <table className="paggingtable"><thead></thead><tbody>
                    <tr><td>
            <button className="paggingButton" onClick={this.subPage}>{this.state.page - 1}</button>
                </td><td>
            <p className="paggingButton">{this.state.page}</p>
                </td><td>
            <button className="paggingButton" onClick={this.addPage}>{this.state.page + 1}</button>
                </td></tr>
            </tbody><tfoot></tfoot></table>
            </td></tr>
            </tbody>
            <tfoot></tfoot>
        </table>
        );
        return theWholeField;
    }
}


export default CatagoryManager;