import React from "react";
//import ReactDOM from "react-dom";
import {Editor, EditorState, RichUtils, ContentState, convertFromHTML, Modifier} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';


import "draft-js/dist/Draft.css";
class MyEditor extends React.Component {
  constructor(props) {    
      super(props);
    this.state = {editorState: EditorState.createEmpty()};
        //editorState: EditorState.createWithContent(ContentState.createFromBlockArray(
        //convertFromHTML(this.props.initital)))};//EditorState.createEmpty()};
    
    // this.clickTwo = this.clickTwo.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
  }

    componentDidMount = () => {
        let stateWithContent = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(this.props.initital)));//ContentState.createFromText(
        let currentSelection = this.state.editorState.getSelection();
        let stateWithContentAndSelection = EditorState.forceSelection(stateWithContent, currentSelection);
        this.setState({
            editorState: stateWithContentAndSelection
        })
        // console.log("initial"); 
        // console.log(this.props.initital);
        // console.log(this.props.initital);
    }

//   componentDidCatch = () => {
//     // this.clickTwo(this);
//   }


  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onChange(newState);
      return "handled";
    }

    return "not-handled";
  }

    onChange = editorState => {
        this.setState({editorState});
        const contentState = editorState.getCurrentContent();
        this.props.upload(stateToHTML(contentState),this.props.zone);
    };

    _onBoldClick() {
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'));
    }
    _onItalicClick() {
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'ITALIC'));
    }

    sendTextToEditor = (text) => {
        this.setState({editorState: this.insertText(text, this.state.editorState)});
        // this.focusEditor();
    }

    insertText = (text, editorState) => {
        const currentContent = editorState.getCurrentContent(),
            currentSelection = editorState.getSelection();

        const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            text
        );

        const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
        return  EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
    }



    clickTwo = () => {
        this.setState({editorState: EditorState.createWithContent(ContentState.createFromBlockArray(
            convertFromHTML(this.props.initital)))});
    }

    render() {
        let letters = ["á","é","í","ó","ú","ü","ñ","É","Á","Í","Ó","Ú","Ü","Ñ","¿","¡"];
        

        return (
            <div style={{ border: "solid" }}> 
            <button onClick={this._onItalicClick.bind(this)}>Italic</button>
            <button onClick={this._onBoldClick.bind(this)}>Bold</button>
            {
                letters.map(letter => {
                    return (
                        <button 
                            name={letter} 
                            onClick={this.sendTextToEditor.bind(this, letter)}
                            // onClick={this._onSpecialLetter}
                        >{letter}</button>
                    )
                })
            }
            <button name="showstuff" onClick={this.clickTwo.bind(this)} hidden={true}>Boldx</button>
                <Editor
                    // initital={EditorState.createWithContent(ContentState.createFromText(this.props.initital))} //{this.state.editorState}
                    editorState={this.state.editorState}//{EditorState.createWithContent(ContentState.createFromText(this.props.initital))} //{this.state.editorState} //{EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(this.props.initital)))}//
                    handleKeyCommand={this.handleKeyCommand}
                    onChange={this.onChange}
                />
            </div>
        );

    }
}

export default MyEditor;