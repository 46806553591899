import React from "react";

class LanguageManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        language:'',
        languages:[],
    }
  }
  
    componentDidMount = () => {
        fetch("https://dev.skinexam.com/languages/read.php").then(res => res.json()).then(result => {
            this.setState({
                languages: result,
            });
        });
    }


  updateLanguage = e => {
      let val = e.target.value;
      this.setState({
          language: val,
      })
  }

  addLanguage = () => {
      const language = this.state.language;
      const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                      Language:language
                })
              };
        fetch("https://dev.skinexam.com/languages/create.php", requestOptions).then(res => res.json()).then(result => {
            console.log(result)
        }).then(() => {
            fetch("https://dev.skinexam.com/languages/read.php").then(res => res.json()).then(result => {
                this.setState({
                    languages: result,
                });
            })
        });
  }


  render() {
    const theWholeField = (
      <div>
          <table>
              <thead></thead>
              <tbody>
              <tr><td>
        <h1>LanguageManager</h1>
        </td></tr><tr><td>
        <input placeholder="Add New Language Here" onChange={this.updateLanguage} />
        <button onClick={this.addLanguage}>Add Language</button>
        </td></tr>
        {
            this.state.languages.map(lang => {
                return (
                    <tr key={Math.random(50000)}><td key={Math.random(50000)}>
                        {lang.id}
                    </td><td key={Math.random(50000)}>
                        {lang.lang}
                    </td></tr>    
                );
            })
            
        }
        </tbody>
        <tfoot></tfoot>
        </table>
      </div>
    );
    return theWholeField;
  }
}

export default LanguageManager;