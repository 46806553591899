import React from "react";

class ProductManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        categories:[],
        products:[],
        shortproducts:[],
        selectedcat:0,
        selecteddes:0,
        updates:[],
        name:"",
        link:"",
        image:"",
        page:0,
        count:15
    }
  }

    componentDidMount = () => {
        const requestOptions = {
          method: "GET",
          headers: {
            "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
            // 'Accept': 'application/json',
            "Content-Type": "application/json",
          },
        };
        fetch("https://dev.skinexam.com/products/read.php?page=" + this.state.page + "&count=" + this.state.count,requestOptions).then(res => res.json()).then(result => {
            this.setState({
                products: result,
                shortproducts: result,
            })
        });
        fetch("https://dev.skinexam.com/product_category/read.php",requestOptions).then(res => res.json()).then(result => {
            this.setState({
                categories:result
            })          
        });
    }

    updateNewName = e => {
        let name = e.target.value;
        this.setState({
            name:name
        },() => {
            
            const requestOptions = {
                method: "GET",
                headers: {
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
                // 'Accept': 'application/json',
                "Content-Type": "application/json",
                },
            };
            fetch("https://dev.skinexam.com/products/read.php?page=" + this.state.page + "&count=" + this.state.count + "&like=" + this.state.name,requestOptions).then(res => res.json()).then(result => {
                this.setState({
                    shortproducts: result,
                })
            });
        })
    }
    updateNewLink = e => {
        let link = e.target.value;
        this.setState({
            link:link
        })
    }
    updateNewImage = e => {
        if (e.key === 'Enter') {
            this.addProduct();   
        }
        let image = e.target.value;
        this.setState({
            image:image
        })
    }

    updateNewcat = e => {
        let cat = e.target.value;
        this.setState({
            selectedcat:cat
        })
    }

    addProduct = () => {
        console.log(this.state);
        const name = this.state.name;
        const link = this.state.link;
        const image = this.state.image;
        const catId = this.state.selectedcat;
        const requestOptions = {
            method: 'POST',
            headers: { 
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,'Content-Type': 'application/json' },
            body: JSON.stringify({
                name:name,
                link:link,
                image:image,
                catId:catId
            })
        };
        fetch("https://dev.skinexam.com/products/create.php", requestOptions).then(res => res.json()).then(result => {
            // console.log(result);
            alert("Added: " + JSON.stringify(result));
            
        });
    }

    updatelink = (e) => {
        // let id = e.target.id;
        // let name = e.target.name;
        // let link = e.target.link;
        // console.log(id + " | " + name + " | " + link);

        const updates = this.state.updates;
        const requestOptions = {
                    method: 'PUT',
                    headers: { 
                        "SKINEXAM-AUTHKEY": this.props.user.AuthKey,'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        updates:updates,
                    })
                };
        fetch("https://dev.skinexam.com/products/update.php", requestOptions).then(res => res.json()).then(result => {
            console.log(result);
            alert("Updated: " + JSON.stringify(result));
        });

    }
    
    addPage = () => {
        let page = this.state.page + 1;
        this.setState({
            page: page
        },() => {
            const requestOptions = {
                method: "GET",
                headers: {
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
                // 'Accept': 'application/json',
                "Content-Type": "application/json",
                },
            };
            fetch("https://dev.skinexam.com/products/read.php?page=" + this.state.page + "&count=" + this.state.count + "&like=" + this.state.name,requestOptions).then(res => res.json()).then(result => {
                this.setState({
                    products: result,
                    shortproducts: result,
                })
            });
        })
    }
    subPage = () => {
        let page = this.state.page - 1;
        if(this.state.page - 1 <= 0)
        {
            page = 0;
        }
        this.setState({
            page: page
        },() => {
            const requestOptions = {
                method: "GET",
                headers: {
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
                // 'Accept': 'application/json',
                "Content-Type": "application/json",
                },
            };
            fetch("https://dev.skinexam.com/products/read.php?page=" + this.state.page + "&count=" + this.state.count + "&like=" + this.state.name,requestOptions).then(res => res.json()).then(result => {
                this.setState({
                    products: result,
                    shortproducts: result,
                })
            });
        })
    }

    deleteProd = e => {
        let id = parseInt(e.target.name);
        const requestOptions = {
            method: 'DELETE',
            headers: { 
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,'Content-Type': 'application/json' },
            body: JSON.stringify({
                id:id,
            })
        };
        fetch("https://dev.skinexam.com/products/delete.php", requestOptions).then(res => res.json()).then(result => {
            console.log(result);
            alert("Deleted: " + JSON.stringify(result));
        });
    }

    updateprodcat = e => {
        let id = e.target.name;
        let val = e.target.value;
        console.log(id + " | " + val);

    }

    
    updateCat = e => {
        let val = e.target.value;
        let id = e.target.name;
        let alterupdates = [...this.state.updates,{'id':id,'cat':val}];
        this.setState({
            updates:alterupdates,
        },() => {
            this.updateCore(id,val,null,null,null);
        })
    }

    updateName = e => {
        let val = e.target.value;
        let id = e.target.name;
        let alterupdates = [...this.state.updates,{'id':id,'name':val}];
        this.setState({
            updates:alterupdates,
        },() => {
            this.updateCore(id,null,val,null,null);
        })
    }

    updateLink = e => {
        let val = e.target.value;
        let id = e.target.name;
        let alterupdates = [...this.state.updates,{'id':id,'link':val}];
        this.setState({
            updates:alterupdates,
        },() => {
            this.updateCore(id,null,null,val,null);
        })
    }

    updateImageLink = e => {
        let val = e.target.value;
        let id = e.target.name;
        let alterupdates = [...this.state.updates,{'id':id,'image':val}];
        this.setState({
            updates:alterupdates,
        },() => {
            this.updateCore(id,null,null,null,val);
        })
    }

    updateCore = (id,catId,name,link,image) => {
        let oldobj = this.state.products.find(obj => parseInt(obj.id) === parseInt(id));
        let newobj = {};
        newobj.id = oldobj.id;
        // newobj.newcat_id = catId
        newobj.product_name = oldobj.product_name;
        newobj.newname = oldobj.newname;
        newobj.product_link = oldobj.product_link;
        newobj.newlink = oldobj.newlink;
        newobj.product_image = oldobj.product_image;
        newobj.newimage = oldobj.newimage;
        newobj.prod_date = oldobj.prod_date;

        if(catId !== '' && catId !== undefined && catId !== null)
        {
            newobj.cat_id = parseInt(catId);
            // newobj.cat_id = parseInt(catId);
        }else{
            newobj.cat_id = parseInt(oldobj.cat_id);
        }
        if(name !== '' && name !== undefined && name !== null)
        {
            newobj.product_name = name;
            newobj.newname = name;
        }else{
            newobj.product_name = oldobj.product_name;
        }
        if(link !== '' && link !== undefined && link !== null)
        {
            newobj.product_link = link;
            newobj.newlink = link;
        }else{
            newobj.product_link = oldobj.product_link;
        }
        if(image !== '' && image !== undefined && image !== null)
        {
            newobj.product_image = image;
            newobj.newimage = image;
        }else{
            newobj.product_image = oldobj.product_image;
        }
        
        let updated = this.state.products.map(obj => (parseInt(obj.id) === parseInt(id) ? newobj : obj));
        
        console.log(updated);
        this.setState({
            products:updated,
            shortproducts:updated
        })
    }




  render() {
    const theWholeField = (
        <table className="producttable">
            <thead></thead>
            <tbody>
                <tr><td>
        <h1>ProductManager</h1>
            </td>
            </tr>
            <tr><td>
        <input id="productname" placeholder="Product Name" onKeyUp={this.updateNewName}/>
        </td><td>
        <input id="productlink" placeholder="Product Link" onKeyUp={this.updateNewLink}/>
        </td>
        <td>
        <DropDown  name="newName" info={this.state.categories} defaultvalue={this.state.selectedcat} onChange={this.updateNewcat}></DropDown>{/** Category Dropdown **/}
        </td><td>
        <input id="productimage" placeholder="Product Image Link" onKeyUp={this.updateNewImage}/>
        </td><td>
        <button onClick={this.addProduct}>Go</button>
        </td>
        </tr>
            {
                this.state.shortproducts.map(prod => {
                    return(
                        <tr key={Math.random(50000)}>
                        <td key={Math.random(50000)}>
                        <input key={Math.random(50000)} name={prod.id} title={prod.product_name} placeholder={prod.product_name} defaultValue={prod.newname} onBlur={this.updateName}/>
                        </td><td key={Math.random(50000)}>
                        <input key={Math.random(50000)} name={prod.id} title={prod.product_link} placeholder={prod.product_link} defaultValue={prod.newlink} onBlur={this.updateLink}/>
                        </td><td key={Math.random(50000)}>
                        <DropDown name={prod.id} info={this.state.categories} defaultvalue={prod.cat_id} onChange={this.updateCat}></DropDown>
                        </td><td key={Math.random(50000)}>
                        <input key={Math.random(50000)} name={prod.id} title={prod.product_image} placeholder={prod.product_image} defaultValue={prod.newimage} onBlur={this.updateImageLink}/>
                        </td><td key={Math.random(50000)}>
                        <a href={prod.product_link}>Test</a>
                        </td><td  key={Math.random(50000)}>
                        {/* <button key={Math.random(50000)} id={prod.id} name={prod.product_name} value={prod.product_link} onClick={this.updatelink}>edit</button>
                        </td><td key={Math.random(50000)}> */}
                        <button name={prod.id}  key={Math.random(50000)} onClick={this.deleteProd}>Delete</button>
                        </td>
                        </tr>
                    );
                })
            }
            <tr><td>
                <button onClick={this.updatelink} className="confirmbutton">Confirm Updates</button>    
            </td><td>
            </td><td>
                <table className="paggingtable"><thead></thead><tbody>
                    <tr><td>
            <button className="paggingButton" onClick={this.subPage}>{this.state.page - 1}</button>
                </td><td>
            <p className="paggingButton">{this.state.page}</p>
                </td><td>
            <button className="paggingButton" onClick={this.addPage}>{this.state.page + 1}</button>
                </td></tr>
            </tbody><tfoot></tfoot></table>
            </td><td>
            </td></tr>
            
            </tbody>
            <tfoot></tfoot>
        </table>
    );
    return theWholeField;
  }
}

class DropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
    const theWholeField = (
        <select name={this.props.name} key={Math.random(50000)} defaultValue={this.props.defaultvalue} onChange={this.props.onChange}>
            <option key={Math.random(50000)} value={0}></option>
            {
                this.props.info.map(data => {
                    return (<option key={Math.random(50000)} value={data.cat_id} name={data.cat_id}>{data.cat_name}</option>)
                })
            }
        </select>
    );
    return theWholeField;
    }
}

export default ProductManager;