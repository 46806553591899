import React from "react";

class KeywordManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories:[],
            selectedcat:0,
            shortkeywords:[],
            keywords:[],
            page:0,
            count:15,
            updates:[],
        }
    }

    componentDidMount = () => {
        const requestOptions = {
            method: "GET",
            headers: {
            "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
            // 'Accept': 'application/json',
            "Content-Type": "application/json",
            },
        };
        fetch("https://dev.skinexam.com/keywords/read.php?page=" + this.state.page + "&count=" + this.state.count,requestOptions).then(res => res.json()).then(result => {
            this.setState({
                keywords: result,
                shortkeywords: result,
            })
        });
        
        fetch("https://dev.skinexam.com/product_category/read.php",requestOptions).then(res => res.json()).then(result => {
            this.setState({
                categories:result
            })          
        });
    }

    updatecat = e => {
        let cat = e.target.value;
        this.setState({
            selectedcat:cat
        })
    }

    updateName = e => {
        let name = e.target.value;
        this.setState({
            name:name
        },() => {
            const requestOptions = {
                method: "GET",
                headers: {
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
                // 'Accept': 'application/json',
                "Content-Type": "application/json",
                },
            };
            fetch("https://dev.skinexam.com/keywords/read.php?page=" + this.state.page + "&count=" + this.state.count + "&like=" + this.state.name,requestOptions).then(res => res.json()).then(result => {
                this.setState({
                    keywords: result,
                    shortkeywords: result,
                })
            });
        })
    }

    addKeyword = () => {
        console.log(this.state.selectedcat + " | " + this.state.name);
        const selectedcat = this.state.selectedcat;
        const name = this.state.name;
        const requestOptions = {
            method: 'POST',
            headers: { 
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,'Content-Type': 'application/json' },
            body: JSON.stringify({
                catId:selectedcat,
                name:name,
            })
        };
        fetch("https://dev.skinexam.com/keywords/create.php", requestOptions).then(res => res.json()).then(result => {
            console.log(result);
            alert("Added: " + JSON.stringify(result));
        });
    }


    addPage = () => {
        let page = this.state.page + 1;
        this.setState({
            page: page
        },() => {
            const requestOptions = {
                method: "GET",
                headers: {
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
                // 'Accept': 'application/json',
                "Content-Type": "application/json",
                },
            };
            fetch("https://dev.skinexam.com/keywords/read.php?page=" + this.state.page + "&count=" + this.state.count + "&like=" + this.state.name,requestOptions).then(res => res.json()).then(result => {
                this.setState({
                    keywords: result,
                    shortkeywords: result,
                })
            });
        })
    }
    subPage = () => {
        let page = this.state.page - 1;
        if(this.state.page - 1 <= 0)
        {
            page = 0;
        }
        this.setState({
            page: page
        },() => {
            const requestOptions = {
                method: "GET",
                headers: {
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
                // 'Accept': 'application/json',
                "Content-Type": "application/json",
                },
            };
            fetch("https://dev.skinexam.com/keywords/read.php?page=" + this.state.page + "&count=" + this.state.count + "&like=" + this.state.name,requestOptions).then(res => res.json()).then(result => {
                this.setState({
                    keywords: result,
                    shortkeywords: result,
                })
            });
        })
    }

    updateCat = e => {
        let val = e.target.value;
        let id = e.target.id;
        let alterupdates = [...this.state.updates,{'id':id,'cat':val}];
        this.setState({
            updates:alterupdates,
        },() => {
            this.updateKeyCore(id,null,val);
        })
    }

    updateKeyword = e => {
        let val = e.target.value;
        let id = e.target.name;
        let alterupdates = [...this.state.updates,{'id':id,'keyword':val}];
        this.setState({
            updates:alterupdates,
        },() => {
            this.updateKeyCore(id,val,null);
        })
    }

    updateKeyCore = (id,keyword,catId) => {
        let oldobj = this.state.keywords.find(obj => parseInt(obj.idNum) === parseInt(id));
        let newobj = {};
        newobj.catId = oldobj.catId;
        newobj.dateCrt = oldobj.dateCrt;
        newobj.newkeyword = keyword;
        newobj.Keyword = oldobj.Keyword;
        newobj.catId = catId;
        newobj.idNum = oldobj.idNum;        

        if(keyword !== '' && keyword !== undefined && keyword !== null)
        {
            newobj.Keyword = keyword;
            newobj.newkeyword = keyword;
        }else{
            newobj.Keyword = oldobj.Keyword;
            newobj.newkeyword = oldobj.Keyword;
        }
        if(catId !== '' && catId !== undefined && catId !== null)
        {
            newobj.catId = parseInt(catId);
        }else{
            newobj.catId = parseInt(oldobj.catId);
        }
        
        let updated = this.state.keywords.map(obj => (parseInt(obj.idNum) === parseInt(id) ? newobj : obj));
        
        console.log(updated);
        this.setState({
            keywords:updated,
            shortkeywords:updated,
        })
    }


    updatekey = e => {
        const updates = this.state.updates;
        const requestOptions = {
            method: 'PUT',
            headers: { 
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,'Content-Type': 'application/json' },
            body: JSON.stringify({
                updates:updates,
            })
        };
        fetch("https://dev.skinexam.com/keywords/update.php", requestOptions).then(res => res.json()).then(result => {
            console.log(result);
            alert("Updated: " + JSON.stringify(result));
        });
    }

    deletekey = e => {        
        let id = parseInt(e.target.name);
        const requestOptions = {
            method: 'DELETE',
            headers: { 
                "SKINEXAM-AUTHKEY": this.props.user.AuthKey,'Content-Type': 'application/json' },
            body: JSON.stringify({
                id:id,
            })
        };
        fetch("https://dev.skinexam.com/keywords/delete.php", requestOptions).then(res => res.json()).then(result => {
            console.log(result);
            alert("Deleted: " + JSON.stringify(result));
        });
    }

    render() {
        const theWholeField = (
            <table className="producttable">
            <thead></thead>
            <tbody>
                <tr><td><h1>KeywordManager</h1></td></tr>
                <tr><td>
                    <input placeholder={"new keyword"} onKeyUp={this.updateName} />
                </td><td>
                    <DropDown id="0" name="original" info={this.state.categories} defaultvalue={this.state.selectedcat} onChange={this.updatecat} />
                </td><td>
                <button onClick={this.addKeyword}>Ad Keyword</button>
                </td>
                </tr>
            {
                this.state.shortkeywords.map(key => {
                    return (<tr key={Math.random(50000)}>
                        <td key={Math.random(50000)}>
                            <input key={Math.random(50000)} title={key.Keyword} placeholder={key.Keyword} defaultValue={key.newkeyword} name={key.idNum} onBlur={this.updateKeyword} />
                        </td><td key={Math.random(50000)}>
                            <DropDown id={key.idNum} info={this.state.categories} defaultvalue={key.catId} name={key.idNum} onChange={this.updateCat}/>
                        </td><td key={Math.random(50000)}>
                            <button key={Math.random(50000)} name={key.idNum} onClick={this.deletekey}>Delete</button>
                        </td>
                    </tr>);
                })
            }
            <tr><td key={Math.random(50000)}>
                <button key={Math.random(50000)} onClick={this.updatekey} className="confirmbutton">Confirm</button>
            </td><td>
                <table className="paggingtable"><thead></thead><tbody>
                    <tr><td>
            <button className="paggingButton" onClick={this.subPage}>{this.state.page - 1}</button>
                </td><td>
            <p className="paggingButton">{this.state.page}</p>
                </td><td>
            <button className="paggingButton" onClick={this.addPage}>{this.state.page + 1}</button>
                </td></tr>
            </tbody><tfoot></tfoot></table>
            </td></tr>
            </tbody>
            <tfoot></tfoot>
        </table>
        );
        return theWholeField;
    }
}



class DropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
    const theWholeField = (
        <select id={this.props.id} name={this.props.name} defaultValue={this.props.defaultvalue} onChange={this.props.onChange} key={Math.random(50000)}>
            <option value={0} key={Math.random(50000)}></option>
            {
                this.props.info.map(data => {
                    return (<option value={data.cat_id} name={data.cat_id} key={Math.random(50000)}>{data.cat_name}</option>)
                })
            }
        </select>
    );
    return theWholeField;
    }
}

export default KeywordManager;