import React from "react";

class KeywordAdManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        inlineads:[],
        page:0,
        count:15,
        newlink:"",
        newkey:""
    };
  }

  componentDidMount = () => {
      fetch("https://dev.skinexam.com/inlineads/read.php?page=" + this.state.page + "&count=" + this.state.count).then(res => res.json()).then(result => {
            this.setState({
                inlineads:result,
            },() => {
                // console.log(result)
            })
        });
  }


    addPage = () => {
        let newPage = this.state.page + 1;
        this.setState({
            page: newPage
        },() => {
            fetch("https://dev.skinexam.com/inlineads/read.php?page=" + this.state.page + "&count=" + this.state.count).then(res => res.json()).then(result => {
                this.setState({
                    inlineads:result,
                },() => {
                    // console.log(result)
                })
            });
        })
    }

    removePage = () => {
        let newPage = this.state.page - 1;
        if(newPage <= 0)
        {
            newPage = 0;
        }
        this.setState({
            page: newPage
        },() => {
            fetch("https://dev.skinexam.com/inlineads/read.php?page=" + this.state.page + "&count=" + this.state.count).then(res => res.json()).then(result => {
                this.setState({
                    inlineads:result,
                },() => {
                    // console.log(result)
                })
            });
        })
    }

    updateCount = e => {
        let count = e.target.value;
        this.setState({
            count:count
        },() => {
            fetch("https://dev.skinexam.com/inlineads/read.php?page=" + this.state.page + "&count=" + this.state.count).then(res => res.json()).then(result => {
                this.setState({
                    inlineads:result,
                },() => {
                    // console.log(result)
                })
            });
        })
    }

    tryLink = e => {
        let link = e.target.name;
        if(!e.target.name.includes('http'))
        {
            link = "https://" + link;
        }
        window.open(link);
    }

    addKeywordAndLink = () => {
        let link = this.state.newlink;
        let keyword = this.state.newkey;
        let obj = {Link:link,Word:keyword}
        
        const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        link:link,
                        keyword:keyword
                    })
                };
        fetch("https://dev.skinexam.com/inlineads/create.php", requestOptions).then(result => {    
            this.setState({
                inlineads: [...this.state.inlineads,obj]
            })   
        });
    }

    updateKey = e => {
        let newkey = e.target.value;
        this.setState({
            newkey: newkey,
        })
    }

    updateLink = e => {
        let newLink = e.target.value;
        this.setState({
            newlink:newLink
        })
    }

    deleteLink = e => {
        let linkId = e.target.name;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                linkId:linkId,
            })
        };
        fetch("https://dev.skinexam.com/inlineads/delete.php", requestOptions).then(res => res.json()).then(result => {
            // let newarr = this.state.inlineads.filter(obj => parseInt(obj.Id) === parseInt(linkId));
            this.setState({
                inlineads:this.state.inlineads.filter(obj => parseInt(obj.Id) !== parseInt(linkId))
            })
        });
    }

  render() {
    const theWholeField = (
      <div>
        <table>
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <h1>Keyword Ad Manager</h1>
              </td>
            </tr>
            <tr><td>
                <input placeholder="Link" id="link" onChange={this.updateLink} /></td>
                <td><input placeholder="Keyword" id="keyword" onChange={this.updateKey} /></td>
                <td><button onClick={this.addKeywordAndLink}>Add</button>
            </td></tr>
            <tr></tr>
          <tr>
                <td>Link</td>
                <td>Keyword</td>
            </tr>
            {
                this.state.inlineads.map(ad => {
                    return(
                    <tr key={Math.random(50000)}>
                        <td key={Math.random(50000)}>
                            <input key={Math.random(50000)} defaultValue={ad.Link} />
                        </td>
                        <td key={Math.random(50000)}>
                            <input key={Math.random(50000)} defaultValue={ad.Word} />                    
                        </td>
                        <td key={Math.random(50000)}>
                            <button key={Math.random(50000)} name={ad.Link} onClick={this.tryLink}>Try Link</button>
                        </td>
                        <td key={Math.random(50000)}>
                            <button key={Math.random(50000)} name={ad.Id} onClick={this.deleteLink}>Delete Key</button>
                        </td>
                    </tr>
                    )
                })
            }
          </tbody>
          <tfoot>
              <tr>
                  <td>
                      <table>
                          <thead></thead>
                          <tbody>
                        <tr>
                            <td><button onClick={this.removePage}>&#60;</button></td>
                            <td><p>{this.state.page}</p></td>
                            <td><input placeholder={this.state.page + 1} /></td>
                            <td><p>{this.state.page + 2}</p></td>
                            <td><button onClick={this.addPage}>&#62;</button></td>
                        </tr>
                        </tbody>
                        <tfoot></tfoot>
                        </table>
                    </td>
                  <td><input defaultValue={this.state.count} onChange={this.updateCount}/></td>
              </tr>
          </tfoot>
        </table>
      </div>
    );
    return theWholeField;
  }
}

export default KeywordAdManager;
