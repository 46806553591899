import React from "react";
import "./index.css";

class DisclaimerEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disclaimers:[]
        }
    }

    componentDidMount = () => {
        fetch("https://dev.skinexam.com/disclaimer/readdisclaimers.php").then(res => res.json()).then(result => {
            this.setState({
                disclaimers:result
            })          
        });
    }

    updateTitle = e => {
        const dis = e.target.name;
        const title = e.target.value;
        let initial = [...this.state.disclaimers];
        let hold = initial.filter(disc => parseInt(dis) !== parseInt(disc.id));
        let extra = initial.find(disc => parseInt(dis) === parseInt(disc.id));
        extra.title = title;
        hold.push(extra);
        hold.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        this.setState({
            disclaimers:hold,
        })
        console.log(hold);
    }

    updateDisclaimer = e => {
        const dis = e.target.name;
        const disclaimer = e.target.value;
        let initial = [...this.state.disclaimers];
        let hold = initial.filter(disc => parseInt(dis) !== parseInt(disc.id));
        let extra = initial.find(disc => parseInt(dis) === parseInt(disc.id));
        extra.disclaimer = disclaimer;
        hold.push(extra);
        hold.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        this.setState({
            disclaimers:hold,
        })
        console.log(hold);
    }

    updateDisclaimers = () => {
        const disclaimers = this.state.disclaimers;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                disclaimers:disclaimers
            })
        };
        fetch("https://dev.skinexam.com/disclaimer/updatedisclaimers.php", requestOptions).then(res => res.json()).then(result => {
            if(result.success)
            {
                alert("Successful update");
            }else{
                alert("Failed To Update");
            }
        });
    }

    render() {
        const theWholeField = (
        <div>
            <table>
                <thead>
                    <tr><td>
                        <h1>DisclaimerEditor</h1>
                    </td></tr>
                    <tr>
                        <th><p>Title</p></th>
                        <th><p>Disclaimer</p></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.disclaimers.map(disclaimer => {    
                            return (
                                <tr key={Math.random(50000)}>
                                    <td key={Math.random(50000)}>
                                        <input key={Math.random(50000)} defaultValue={disclaimer.title} name={disclaimer.id} onBlur={this.updateTitle}/>
                                    </td>
                                    <td key={Math.random(50000)}>
                                        <input key={Math.random(50000)} defaultValue={disclaimer.disclaimer} name={disclaimer.id} onBlur={this.updateDisclaimer}/>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr><td><button onClick={this.updateDisclaimers}>Save</button></td><td></td></tr>
                </tbody>
                <tfoot></tfoot>
            </table>
        </div>
        );
        return theWholeField;
    }
}

export default DisclaimerEditor;