import React from "react";

class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        email:"",
    }
  }


  updateEmail = e => {
      let email = e.target.value;
      this.setState({
          email:email
      })
  }

    requestReset = () => {
        const email = this.state.email;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email:email,
            })
        };
        fetch("https://dev.skinexam.com/recoverydr/recover.php", requestOptions).then(res => res.json()).then(result => {
            console.log(result);
        });
    }


  render() {
    const theWholeField = (
      <div>
          <table>
              <thead></thead>
              <tbody>
                  <tr>
                      <td>
                        <h1>Forgot</h1>
                    </td></tr><tr><td>
                        <label htmlFor="email">Email</label>
                    </td></tr><tr><td>
                        <input id="email" onChange={this.updateEmail} placeholder="John@Example.com" />
                    </td></tr><tr><td>
                        <button onClick={this.requestReset}>Request Reset</button>
                    </td>
                  </tr>
              </tbody>
              <tfoot></tfoot>
            </table>
      </div>
    );
    return theWholeField;
  }
}

export default Forgot;