import React from "react";
// import Loading from "react-loading";
import {
  Redirect,
} from "react-router-dom";
import ReactLoading from 'react-loading';

class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        redirect:false,
        redirectforgot:false,
    };
}

  componentDidMount = () =>{
      this.props.setUserData({});
      this.props.setLoggedIn(0);
  }

    signin = () => {
        const Email = document.getElementById("email").value;
        const Password = document.getElementById("password").value;
        const requestOptions = {
                    method: 'POST',
                    headers: { 
                        'Content-Type': 'application/json' 
                    },
                    body: JSON.stringify({
                        Email:Email,
                        Password:Password,
                    }),
                };
        fetch("https://dev.skinexam.com/drlogin/read.php", requestOptions).then(res => res.json()).then(result => {
            console.log(result.DrId);
            console.log(result);  
            if(result.message === "Failed")
            {
                this.setState({
                    isloading:false,
                },() => alert("Account or password incorrect"))
            }else{
                this.props.setUserData(result);
                this.setState({
                    isloading:false,
                    redirect:true,
                },() => {
                    this.props.setLoggedIn(1);
                })
            }
        });
    };

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            document.getElementById("signin").click();
        }
      }

      forgot = () => {
          this.setState({
              redirectforgot:true
          })
      }

  render() {

    const theWholeField = (
      <div>
        <table className="middleFloat">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <h1>Sign In</h1>
              </td>
              <td>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="email">Email</label>
              </td>
            </tr>
            <tr>
              <td>
                <input placeholder="email" id="email" />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="password">Password</label>
              </td>
            </tr>
            <tr>
              <td>
                <input placeholder="password" type="password" id="password" onKeyPress={this.handleKeyPress} />
              </td>
            </tr>
            <tr>
              <td>
                <button id="signin" onClick={this.signin} className="signinButton">Sign In</button>
                <button onClick={this.forgot} className="forgotButton">
                  Forgot?
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
    );

    let loading = (
        <div>
            <ReactLoading type={"bars"} color={"grey"} height={'50px'} width={'100px'} />
        </div>
    );
    if(this.state.isloading)
    {
        return loading;
    }else if(this.state.redirect){
        return <Redirect to='/answertranslator'/>;
    }else if(this.state.redirectforgot){
        return <Redirect to='/forgot'/>;
    }else{
        return theWholeField;
    }
  }
}

export default Signin;
