import React from "react";

class AdManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Links: [],
      projects: [],
      page: 1,
      numberlinks: 15,
      firstlinkid: 0,
      maxpages: 99,
      diseases: [],
    };
  }

  componentDidMount = () => {
    this.getLinks();
    //this.getProjects();
    this.getDiseases();
  };

  getDiseases = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
        // 'Accept': 'application/json',
        "Content-Type": "application/json",
      },
    };
    fetch("https://dev.skinexam.com/diseases/read.php", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        if (result !== undefined) {
          this.setState({
            diseases: result,
          });
        }
      });
  };

  getLinks = () => {
    const page = this.state.page;
    const numberlinks = this.state.numberlinks;
    const requestOptions = {
      method: "GET",
      headers: {
        "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
        // 'Accept': 'application/json',
        "Content-Type": "application/json",
      },
    };

    fetch(
      "https://dev.skinexam.com/links/read.php?Manager=1&Page=" +
        page +
        "&Count=" +
        numberlinks,
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        if (result !== undefined) {
          this.setState({
            Links: result,
          });
        }
      });
  };

  downOne = () => {
    let newpage = this.state.page - 1;
    if (newpage === 0) {
      newpage = 1;
    }
    this.setState(
      {
        page: newpage,
      },
      () => {
        this.getLinks();
      }
    );
  };

  upOne = () => {
    let newpage = this.state.page + 1;
    if (newpage > this.state.maxpages) {
      newpage = this.state.maxpages;
    }
    this.setState(
      {
        page: newpage,
      },
      () => {
        this.getLinks();
      }
    );
  };

  updateNumber = (e) => {
    let num = e.target.value;
    this.setState(
      {
        numberlinks: num,
      },
      () => {
        this.getLinks();
      }
    );
  };

  updatePage = (e) => {
    let newpage = e.target.value;
    if (newpage > this.state.maxpages) {
      newpage = this.state.maxpages;
    }
    if (newpage < 1) {
      newpage = 1;
    }
    this.setState(
      {
        page: newpage,
      },
      () => {
        this.getLinks();
      }
    );
  };

  render() {
    const theWholeField = (
      <div key={Math.random(50000)}>
          <table>
              <thead><tr></tr></thead>
              <tbody><tr><td>
          <h2>Add Links</h2>
          <table key={Math.random(50000)}>
            <thead key={Math.random(50000)}></thead>
            <tbody key={Math.random(50000)}>
              <LinkAdder
                user={this.props.user} diseases={this.state.diseases} />
            </tbody>
            <tfoot key={Math.random(50000)}></tfoot>
          </table>
          <h2>Manage Links</h2>
          <table key={Math.random(50000)}>
            <thead key={Math.random(50000)}></thead>
            <tbody key={Math.random(50000)}>
              {this.state.Links.map((Link) => {
                return (
                  <AdDiv
                    user={this.props.user} 
                    key={Math.random(50000)}
                    Link={Link}
                    projects={this.state.projects}
                    diseases={this.state.diseases}
                    idIn={Link.AnswerId}
                  />
                );
              })}
            </tbody>
            <tfoot key={Math.random(50000)}></tfoot>
          </table>
            </td></tr></tbody>
            <tfoot><tr>
                <td>
                    <div className="centerBottom">
                        <label htmlFor="pageNumber">Page</label>
                        <button onClick={this.downOne}>{"<"}</button>
                        <input defaultValue={this.state.page} onBlur={this.updatePage} />
                        <button onClick={this.upOne}>{">"}</button>
                        <label htmlFor="pageInputSet">Number Of Results Per Page</label>
                        <input
                            id="pageInputSet"
                            defaultValue={this.state.numberlinks}
                            onBlur={this.updateNumber}
                        />
                    </div>
                </td>
            </tr></tfoot>
        </table>
        </div>
    );
    return theWholeField;
  }
}

class AdDiv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  gotoLink = (e) => {
    let link = e.target.name;
    let id = e.target.id.substring(10);    // "testbutton";
    link = document.getElementById(id + "Link").value;
    window.open(link, "_blank");
  };

  updateBroken = (e) => {
    let id = e.target.name;
    let broken = e.target.checked;
    const requestOptions = {
      method: "PUT",
      headers: { 
        "SKINEXAM-AUTHKEY": this.props.user.AuthKey,"Content-Type": "application/json" },
      body: JSON.stringify({
        LinkId: id,
        Broken: broken,
      }),
    };
    fetch("https://dev.skinexam.com/links/update.php", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
      });
  };

  updateURL = (e) => {
    let id = e.target.name;
    let url = document.getElementById(id + "Link").value;
    
    const requestOptions = {
      method: "PUT",
      headers: { 
        "SKINEXAM-AUTHKEY": this.props.user.AuthKey,"Content-Type": "application/json" },
      body: JSON.stringify({
        LinkId: id,
        Link: url,
      }),
    };
    fetch("https://dev.skinexam.com/links/update.php", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
      });
  };

  updateDescription = (e) => {
    let id = e.target.name;
    let desc = document.getElementById(id + "LinkDescription").value;
    const requestOptions = {
      method: "PUT",
      headers: { 
        "SKINEXAM-AUTHKEY": this.props.user.AuthKey,"Content-Type": "application/json" },
      body: JSON.stringify({
        LinkId: id,
        LinkDescription: desc,
      }),
    };
    fetch("https://dev.skinexam.com/links/update.php", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
      });
  };

  
  updateLanguage = (e) => {
    let targ = e.target.name.split('-');
    let id = targ[1];
    let answerId = targ[0]; 
    let langId = e.target.value;
    console.log(id + " | " + answerId + " | " + langId);
    const requestOptions = {
      method: "PUT",
      headers: { 
        "SKINEXAM-AUTHKEY": this.props.user.AuthKey,"Content-Type": "application/json" },
      body: JSON.stringify({
        LinkId: parseInt(id),
        DisId: parseInt(answerId),
        LangId: parseInt(langId)
      }),
    };
    fetch("https://dev.skinexam.com/links/update.php", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
      });
  };


  updateBase = (e) => {
    let id = e.target.name;
    let base = 0;
    if (e.target.checked) {
      base = 1;
    }

    const requestOptions = {
      method: "PUT",
      headers: { 
        "SKINEXAM-AUTHKEY": this.props.user.AuthKey,"Content-Type": "application/json" },
      body: JSON.stringify({
        LinkId: id,
        Base: base,
      }),
    };
    fetch("https://dev.skinexam.com/links/update.php", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
      });
  };

  updatePrescription = (e) => {
    let id = e.target.name;
    let pres = 0;
    if (e.target.checked) {
      pres = 1;
    }
    const requestOptions = {
      method: "PUT",
      headers: { 
        "SKINEXAM-AUTHKEY": this.props.user.AuthKey,"Content-Type": "application/json" },
      body: JSON.stringify({
        LinkId: id,
        Prescription: pres,
      }),
    };
    fetch("https://dev.skinexam.com/links/update.php", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
      });
  };

  render() {
    const theWholeField = (
      <tr key={Math.random(50000)}>
        <td key={Math.random(50000)}>
            <label htmlFor={this.props.Link.Id + "working"} key={Math.random(50000)}>Working</label>
          <input
            id={this.props.Link.Id + "working"}
            name={this.props.Link.Id}
            key={Math.random(50000)}
            type="checkbox"
            defaultChecked={this.props.Link.Broken}
            onClick={this.updateBroken}
          />
        </td>
        <td key={Math.random(50000)}>
          <button
            key={Math.random(50000)}
            name={this.props.Link.Link}
            id={"testbutton" + this.props.Link.Id}
            onClick={this.gotoLink}
          >
            Test Link
          </button>
        </td>
        <td key={Math.random(50000)}>
          <input
            key={Math.random(50000)}
            type="text"
            name={this.props.Link.Id}
            id={this.props.Link.Id + "Link"}
            defaultValue={this.props.Link.Link}
          />
        </td>
        <td>
          <button name={this.props.Link.Id} onClick={this.updateURL}>
            Update URL
          </button>
        </td>
        <td key={Math.random(50000)}>
          <input
            key={Math.random(50000)}
            type="text"
            name={this.props.Link.Id}
            id={this.props.Link.Id + "LinkDescription"}
            defaultValue={this.props.Link.LinkDescription}
          />
        </td>
        <td>
          <button name={this.props.Link.Id} onClick={this.updateDescription}>
            Update Description
          </button>
        </td>
        <td key={Math.random(50000)}>
          <DeseaseDropdown
                user={this.state.userdata}
            diseases={this.props.diseases}
            idIn={this.props.Link.DiseaseId}
            LinkId={this.props.Link.Id}
          />
        </td>
        <td>
            <select key={Math.random(50000)} defaultValue={this.props.Link.LangId} name={this.props.Link.DiseaseId + '-' + this.props.Link.Id} onChange={this.updateLanguage}>
                <option value="1" key={Math.random(50000)}>English</option>
                <option value="20" key={Math.random(50000)}>Spanish</option>
                <option value="21" key={Math.random(50000)}>Arabic</option>
            </select>
        </td>
        <td>
          {/* <label htmlFor={this.props.Link.Id + "base"}>Base</label>
          <input
            type="checkbox"
            id={this.props.Link.Id + "base"}
            name={this.props.Link.Id}
            onClick={this.updateBase}
            defaultChecked={this.props.Link.BaseLink === 1}
          /> */}
        </td>
        <td>
          <label htmlFor={this.props.Link.Id + "pres"}>Prescription</label>
          <input
            type="checkbox"
            id={this.props.Link.Id + "pres"}
            name={this.props.Link.Id}
            onClick={this.updatePrescription}
            defaultChecked={this.props.Link.Pres === 1}
          />
        </td>
        {/* <td key={Math.random(50000)}>
          <ProjectDropdown projects={this.props.projects} />
        </td> */}
      </tr>
    );
    return theWholeField;
  }
}

class DeseaseDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateAnswerId = (e) => {
    let id = e.target.name;
    let answerId = e.target.value;
    // console.log(id + " | " + answerId);
    const requestOptions = {
      method: "PUT",
      headers: { 
        "SKINEXAM-AUTHKEY": this.props.user.AuthKey,"Content-Type": "application/json" },
      body: JSON.stringify({
        LinkId: id,
        AnswerId: answerId,
      }),
    };
    fetch("https://dev.skinexam.com/links/update.php", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
      });
  };

  render() {
    const theWholeField = (
      <select
        id="diseaseDropdown"
        defaultValue={this.props.idIn}
        name={this.props.LinkId}
        onChange={this.updateAnswerId}
      >
        <option>Select A Disease</option>
        {this.props.diseases.map((disease) => {
          return (
            <option
              key={Math.random(50000)}
              id={disease.DiseaseId}
              value={disease.DiseaseId}
            >
              {disease.DiseaseName}
            </option>
          );
        })}
      </select>
    );
    return theWholeField;
  }
}

class LinkAdder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  addLink = () => {
    const Link = document.getElementById("newLink").value;
    const LinkDescription = document.getElementById("newLinkDesc").value;
    const Disease = document.getElementById("NewDiseaseDropdown").value;
    const Pres = document.getElementById("NewPres").checked;
    const Lang = document.getElementById("linkaddlang").value;
    // const Base = document.getElementById("NewBase").checked;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "SKINEXAM-AUTHKEY": this.props.user.AuthKey,
      },
      body: JSON.stringify({
        Link: Link,
        LinkDescription: LinkDescription,
        Disease: Disease,
        Pres: Pres,
        Base: true,
        Lang: Lang,
      }),
    };
    fetch("https://dev.skinexam.com/links/create.php", requestOptions)
      .then((res) => {
        // console.log(res);
        res.json();
      })
      .then((result) => {
        // console.log(result);
      });
  };

  testLink = () => {
    let link = document.getElementById("newLink").value;
    // must require https://www
    // make checking later
    window.open(link, "_blank");
  };

  render() {
    const theWholeField = (
      <tr style={{ margin: "5px", backgroundColor: "orange" }}>
        <td>
          <button onClick={this.addLink}>Add Link</button>
        </td>
        <td>
          <label htmlFor="newLink">New Link</label>
        </td>
        <td>
          <input id="newLink" placeholder="New Link" />
        </td>
        <td>
          <label htmlFor="newLinkDesc">Link Description</label>
        </td>
        <td>
          <input id="newLinkDesc" placeholder="New Link Description" />
        </td>
        <td>
          <button onClick={this.testLink}>Test Link</button>
        </td>
        <td>
          <select
            id="NewDiseaseDropdown"
            name={"AddDiseaseSelect"}
            onChange={this.updateAnswerId}
          >
            <option>Select A Disease</option>
            {this.props.diseases.map((disease) => {
              return (
                <option
                  key={Math.random(50000)}
                  id={disease.DiseaseId}
                  value={disease.DiseaseId}
                >
                  {disease.DiseaseName}
                </option>
              );
            })}
          </select>
        </td>
        <td>
            <select key={Math.random(50000)} id="linkaddlang">
                <option value="1" key={Math.random(50000)}>English</option>
                <option value="20" key={Math.random(50000)}>Spanish</option>
                <option value="21" key={Math.random(50000)}>Arabic</option>
            </select>
        </td>
        <td>
          <label htmlFor="NewPres">Prescription</label>
          <input type="checkbox" id="NewPres" />
        </td>
      </tr>
    );
    return theWholeField;
  }
}

// class ProjectDropdown extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }
//   render() {
//     const theWholeField = (
//       <select>
//         <option>Select A Project</option>
//         {this.props.pojects.map((project) => {
//           return (
//             <option key={Math.random(50000)} id={project.id}>
//               {project.title}
//             </option>
//           );
//         })}
//       </select>
//     );
//     return theWholeField;
//   }
// }

export default AdManager;
