import React from "react";
// import ReactLoading from 'react-loading';
import { Redirect } from "react-router";


class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isloading:false,
        redirect:false,
    };
  }

  createAccount = () => {
      this.setState({
          isloading:true
      })
      let email = document.getElementById("email").value;
      const Password = document.getElementById("password").value;
      const FName = document.getElementById("fname").value;
      const LName = document.getElementById("lname").value;
      const MedSchoolName = "s";// document.getElementById("medical").value;
      const YearGrad = "1234";// document.getElementById("gradyear").value;
      const PaypalInfo = "s";// document.getElementById("paypalemail").value;

      const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                      Email:email,
                      Password:Password,
                      FName:FName,
                      LName:LName,
                      MedSchoolName:MedSchoolName,
                      YearGrad:YearGrad,
                      PaypalInfo:PaypalInfo,
                  })
              };
      fetch("https://dev.skinexam.com/drlogin/create.php", requestOptions).then(res => res.json()).then(result => {
            console.log(result);  
            if(result.message === "Failed")
            {
                this.setState({
                    isloading:false,
                },() => alert("signup failed"))
            }else{
                this.setState({
                    isloading:false,
                    redirect:true,
                })    
            }
        });
  }

  checkYear = e => {
    let text = e.target.value;
    if(text.length < 5)
    {
        e.target.value = text.substring(0,4);
    }
  }

  render() {
    const theWholeField = (
      <div>
        <table className="middleFloat">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <h1>Sign Up</h1>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="email">Email</label>
              </td>
            </tr>
            <tr>
              <td>
                <input id="email" placeholder="JohnDoe@example.com" />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="password">Password</label>
              </td>
            </tr>
            <tr>
              <td>
                <input id="password" type="password" placeholder="********" />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="verpassword">Verify Password</label>
              </td>
            </tr>
            <tr>
              <td>
                <input id="verpassword" type="password" placeholder="********" />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="fname">First Name</label>
              </td>
            </tr>
            <tr>
              <td>
                <input id="fname" placeholder="John" />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="lname">Last Name</label>
              </td>
            </tr>
            <tr>
              <td>
                <input id="lname" placeholder="Doe" />
              </td>
            </tr>
            {/* <tr>
              <td>
                <label htmlFor="medical">Medical School </label>
              </td>
            </tr>
            <tr>
              <td>
                <input id="medical" placeholder="Harvard Medical School" />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="gradyear">Graduation Year</label>
              </td>
            </tr>
            <tr>
              <td>
                <input onKeyUp={this.checkYear} id="gradyear" type="number" min="1920" max="2050" minLength="4" maxLength="4" defaultValue="1975" />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="paypalemail">Paypal Email</label>
              </td>
            </tr>
            <tr>
              <td>
                <input id="paypalemail" placeholder="JohnDoe@example.com" />
              </td>
            </tr> */}
            <tr>
              <td>
                <button onClick={this.createAccount}>Sign Up</button>
              </td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
    );

    // let loading = (
    //     <div>
    //         <ReactLoading type={"bars"} color={"grey"} height={'50px'} width={'100px'} />
    //     </div>
    // );
    if(this.state.redirect)
    {
        return <Redirect to='/signin'/>;
    // }else if(this.state.isloading)
    // {
        // return loading;
    }else{
        return theWholeField;
    }
  }
}

export default SignUp;
